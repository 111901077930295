<template>
  <div>
    <vx-card>
      <h3 class="p-2 mb-2">{{ $t("Add_File") }}</h3>
      <input
        :disabled="!fileUploadEnable"
        :class="{
          inputButton: fileUploadEnable,
          inputButtonDisabled: !fileUploadEnable,
        }"
        multiple
        accept=".zip, .pdf, .png"
        type="file"
        id="files"
        ref="files"
        v-on:change="handleFileUpload"
      />
      <label class="ml-2" for="files">{{ $t("Select_File") }}</label>

      <h3 class="p-2 mt-6 mb-2">{{ $t("Uploaded_Files") }}</h3>

      <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
        <fileComp
          class="p-2"
          :key="file.name"
          v-for="file in files"
          :file="file"
          @removeFile="handleRemoveFile($event)"
        />
      </div>

      <fileList
        class="pb-2"
        v-for="file in filesList"
        :key="file.id"
        :file="file"
        @fileDeleted="getFiles"
      />
    </vx-card>

    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage()"
      />
    </div>
  </div>
</template>

<script>
import API from "@/api/config";
import Api from "@/api/api";

import fileComp from "./components/File";
import fileList from "./components/FileList";

export default {
  data() {
    return {
      files: "",
      fileUploadEnable: true,
      filesList: [],

      currentPage: 1,
      totalPage: 1,
    };
  },
  components: {
    fileComp,
    fileList,
  },
  methods: {
    handleChangePage() {
      this.getFiles();
    },
    getFiles() {
      var params = {};

      if (this.currentPage != 1) {
        params.id = this.getOrder.id;
        params.page = this.currentPage;
        this.$router.push({ query: params }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(API.BASEURL + API.DENTAL_FILES, this.handleGetFiles, params);
    },
    handleGetFiles(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        this.filesList = data.results;
        this.totalPage = Math.ceil(data.count / 20);
      }
    },
    handleRemoveFile(fileName) {
      var newFile = [];
      for (let index = 0; index < this.files.length; index++) {
        if (this.files[index].name != fileName) {
          newFile.push(this.files[index]);
        }
      }
      this.files = newFile;
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
    },
  },
  created() {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }
  },
};
</script>

<style scoped>
.fileTitle {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inputButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputButton + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
.inputButtonDisabled {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputButtonDisabled + label {
  color: rgba(var(--vs-primary), 1);
  border: 1px solid rgba(var(--vs-primary), 1);
  background: transparent !important;
  opacity: 0.5;
  display: inline-block;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>
